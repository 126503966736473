<h1 align="center">
    Modification du compte d'utilisateur {{this.groupeDeControle.controls.identifiant.value}}
</h1>

<div class="formulaire">

    <div [ngClass]="{ 'messageErreur': this.traitementErreurs.nombreErreurs() != 0 }">
        <li *ngFor="let erreur of this.traitementErreurs.obtenirListeErreurs()">
            {{erreur}}
        </li>
    </div>

    <div [ngClass]="{ 'messageSucces': this.message.nombreMessages() != 0 }">
        <li *ngFor="let message of this.message.obtenirListeMessages()">
          {{message}}
        </li>
    </div>
    <div class="saisieIdentifiant">
        <mat-form-field appearance="outline" floatLabel="always" class="identifiant">
            <mat-label>
                <b>Identifiant</b>
            </mat-label>
            <input matInput value="{{this.groupeDeControle.controls.identifiant.value}}" disabled>
        </mat-form-field>
        <div class="separateurIdentifiant"></div>
        <button mat-mini-fab color="basic" *ngIf="this.groupeDeControle.controls.actif.value && this.groupeDeControle.controls.actif.value!=null && this.isAdmin" (click)="verrouillerCompte()" matTooltip="Verrouiller" matTooltipPosition="above"><i class="material-icons">lock_open</i></button>
        <button mat-mini-fab color="basic" *ngIf="!this.groupeDeControle.controls.actif.value && this.groupeDeControle.controls.actif.value!=null && this.isAdmin" (click)="verrouillerCompte()" matTooltip="Déverrouiller" matTooltipPosition="above"><i class="material-icons">lock</i></button>
        <div *ngIf="this.groupeDeControle.controls.actif.value==null" class="separateurEntreVerroullerEtRetirer"></div>
        <div class="separateurEntreBouton"></div>
        <button mat-button class="boutonRetirer" *ngIf="this.isAdmin" (click)="confirmationRetraitCompte()"> Retirer </button>
    </div>

    <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>
            <b>Prénom</b>
        </mat-label>
        <input matInput [formControl]="this.groupeDeControle.controls.prenom" #prenomHTML (keyup.enter)="modifierInformationsUtilisateur()">
        <mat-icon matSuffix>person</mat-icon>
    </mat-form-field>

    <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>
            <b>Nom</b>
        </mat-label>
        <input matInput [formControl]="this.groupeDeControle.controls.nom" #nomHTML (keyup.enter)="modifierInformationsUtilisateur()">
        <mat-icon matSuffix>person</mat-icon>
    </mat-form-field>
    <mat-form-field class="saisieCourriel" appearance="outline" floatLabel="always">
        <mat-label>
            <b>Courriel</b>
        </mat-label>
        <input matInput type="email" [formControl]="this.groupeDeControle.controls.courriel" #courrielHTML (keyup.enter)="modifierInformationsUtilisateur()">
        <mat-icon matSuffix>mail</mat-icon>
    </mat-form-field>

    <mat-form-field appearance="outline" floatLabel="always" *ngIf="this.isAccountExpiresActive" class="height-datepicker accountExpiresRole">
        <mat-label><b>Date écheance <span style="color:red">*</span></b></mat-label>
        <input matInput [ngxMatDatetimePicker]="picker" placeholder="AAAA-MM-JJ HH:mm" #accountExpiresHTML
        [formControl]="this.groupeDeControle.controls.accountExpires" (keyup.enter)="modifierInformationsUtilisateur()"
        >
        
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    
        <ngx-mat-datetime-picker #picker></ngx-mat-datetime-picker>
    </mat-form-field>

    <app-coordonnees></app-coordonnees>
    <div class="separateur"></div>
    <app-association-groupes></app-association-groupes>
    <div class="separateur"></div>
    <div class="separateur"></div>

    <!-- a (click)="renvoyerCourrielInitialisation()" class="lienReinitialisation ">Envoyer à nouveau le courriel d'initialisation du mot de passe</a -->
    <a (click)="reinitialiserPassword()" class="lienReinitialisation ">Envoyer à nouveau le courriel d'initialisation du mot de passe</a>
    <!-- div class="separateur"></div -->
    <a (click)="reinitialiserOtp()"
    [class.disabled]="isInitOtpDisabled()"
    class="lienReinitialisation ">Réinitialiser le code QR de cet utilisateur</a>
    <div class="separateur"></div>
    <div class="bouton">
        <button mat-raised-button (click)="modifierInformationsUtilisateur()"
        [disabled]="this.groupeDeControle.invalid || this.coordonneesComponent.userAddressValidations.invalid">Sauvegarder</button>
        <div class="separateurEntreBouton"></div>
        <button class="boutonAnnuler" mat-raised-button (click)="retournerAuTableau()">Annuler</button>
    </div>
</div>