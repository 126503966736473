<div class="dialogue" (keyup.enter)="fermerSession()">
    <h2 mat-dialog-title>Votre session expirera dans {{data.tempsExpirationSec}} secondes</h2>
    <div [ngClass]="{ 'messageErreur': this.traitementErreurs.nombreErreursDialogue() != 0 }">
          <li *ngFor="let erreur of this.traitementErreurs.obtenirListeErreursDialogue()">
              {{erreur}}
          </li>
      </div>
    <p>Voulez-vous continuer dans votre session?</p>
    <br>
    <div class="centreButtons">
      <button mat-raised-button class="boutonRetirer" cdkFocusInitial [mat-dialog-close]="data.name">Oui</button>
      <!--<button mat-raised-button class="boutonAnnuler" (click)="fermerSession()" (keyup.enter)="fermerSession()">Non</button>-->
    </div>
  </div>
  