import { Component, OnInit, Inject, ViewChild } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from '../services/messages/message.service';
import { HttpClientSx5 } from '../services/client-http-sx5/http.client.sx5';
import { TraitementErreurService } from '../services/traitement-erreurs/traitement-erreur.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { KeycloakService } from 'keycloak-angular';
import { environment } from '../../environments/environment';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AssociationGroupesComponent } from '../association-groupes/association-groupes.component';
import { CoordonneesComponent } from '../coordonnees/coordonnees.component';

import * as moment from 'moment';

const PREMIER_COURRIEL_DEFAUT = 0;

export interface donneesDialogue {
  identifiant: string;
}

@Component({
  selector: 'formulaireModification',
  styleUrls: ['formulaireModification.css'],
  templateUrl: 'formulaireModification.html',
})

export class formulaireModification implements OnInit {

  messageVerouille = '';
  isAdmin = false;
  isAccountExpiresActive = false;
  privileges = [];
  groupeDeControle: UntypedFormGroup;
  @ViewChild(AssociationGroupesComponent, {static: true}) associationGroupesComponent: AssociationGroupesComponent;
  @ViewChild(CoordonneesComponent, {static: true}) coordonneesComponent: CoordonneesComponent;

  constructor(private clientHTTPSX5: HttpClientSx5,
              private routeurActive: ActivatedRoute,
              private routeur: Router,
              public message: MessageService,
              public traitementErreurs: TraitementErreurService,
              public dialogue: MatDialog,
              private keycloakAngular: KeycloakService) {
      this.groupeDeControle = new UntypedFormGroup({
        identifiant: new UntypedFormControl('', [Validators.required]),
        prenom    : new UntypedFormControl('', [Validators.required]),
        nom       : new UntypedFormControl('', [Validators.required]),
        courriel  : new UntypedFormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z0-9_+&*-]+(?:\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,7}$/)]),
        actif     : new UntypedFormControl('', []),
        accountExpires: new UntypedFormControl('', [])
      });
      this.isAccountExpiresActive = environment.isAccountExpiresActive;
      this.routeurActive.params.subscribe(params => this.groupeDeControle.controls.identifiant.setValue(params['identifiant']));
      //const currentYear = new Date();
      //this.minDate = new Date(currentYear.getFullYear(), currentYear.getMonth(), currentYear.getDate());
      //this.maxDate = new Date(currentYear.getFullYear() + 1, currentYear.getMonth(), currentYear.getDate())
  }

  ngOnInit(): Promise<any> {
    this.verifierPermissionsAdmin();
    this.traitementErreurs.viderErreurs();
    this.obtenirInformationsUtilisateur();
    return;
  }

  verifierPermissionsAdmin() {
    this.isAdmin = false;
    if (this.usagerDanstokenEstPresent()) {
      for (const role of this.obtenirRolesUsager()) {
        if ((role).includes('sx5-admin')) {
          this.isAdmin = true;
          break;
        }
     }
    } else {
      console.log('Le token n\'est pas disponible ou lisible');
      console.log('environment.keycloakclientid = ' + environment.keycloakclientid);
    }
  }

  obtenirRolesUsager(): string[] {
    return this.keycloakAngular.getKeycloakInstance().tokenParsed.resource_access[environment.keycloakclientid].roles;
  }

  usagerDanstokenEstPresent(): boolean {
    return (this.keycloakAngular.getKeycloakInstance() !== undefined &&
            this.keycloakAngular.getKeycloakInstance().tokenParsed !== undefined) &&
            this.keycloakAngular.getKeycloakInstance().tokenParsed.resource_access[environment.keycloakclientid] !== undefined;
  }

  retournerAuTableau(): Promise<boolean> {
    this.message.viderMessage();
    return this.routeur.navigate(['']);
  }


  modifierInformationsUtilisateur() {
    this.viderAllMessages();
    this.coordonneesComponent.remplirCoordonnees();
    let formattedDate = this.isAccountExpiresActive ? moment(this.groupeDeControle.controls.accountExpires.value).format('YYYY-MM-DDTHH:mm:00') : undefined;
    this.clientHTTPSX5.modifierUtilisateurPUTHttp(this.groupeDeControle.controls.identifiant.value,
                                                  this.groupeDeControle.controls.prenom.value,
                                                  this.groupeDeControle.controls.nom.value,
                                                  this.groupeDeControle.controls.courriel.value,
                                                  this.groupeDeControle.controls.actif.value,
                                                  this.associationGroupesComponent.listeGroupesAffectes,
                                                  this.coordonneesComponent.phoneNumbersEntityList,
                                                  this.coordonneesComponent.userAddressValidations.controls.lieuTravail.value,
                                                  formattedDate).subscribe(
      () => {
        this.message.ajouterUnMessageALaListeDeMessage('Modification du compte ' + this.groupeDeControle.controls.identifiant.value + ' effectuée avec succès.');
        //this.retournerAuTableau()
      },
      (erreur: string) => {
        this.traitementErreurs.traitementErreur(erreur)
      }
    );
  }

  modifierDateAccountExpires(e) {

  }

  enregistrerInformationsUtilisateur(result) {
    this.groupeDeControle.controls.prenom.setValue(result.name.givenName);
    this.groupeDeControle.controls.nom.setValue(result.name.familyName);
    this.groupeDeControle.controls.courriel.setValue(result.emails[PREMIER_COURRIEL_DEFAUT].value);
    this.groupeDeControle.controls.actif.setValue(result.active);
    
    if (this.isAccountExpiresActive
    && result["urn:sx5:scim:api:messages:2.0:UserExtention"] != undefined){
      let value = new Date(result["urn:sx5:scim:api:messages:2.0:UserExtention"].accountExpires);
      this.groupeDeControle.controls.accountExpires.setValue(value);
    }
    this.privileges = result.entitlements;
    this.coordonneesComponent.affecterCoordonnees(result);
    this.associationGroupesComponent.affecterGroupes(result);
  };

  renvoyerCourrielInitialisation() {
    this.viderAllMessages();
    this.clientHTTPSX5.envoyerCourrielInitialisationPOSTHttp(this.groupeDeControle.controls.identifiant.value).subscribe(
      resultat => {
        this.message.ajouterUnMessageALaListeDeMessage('Un courriel d\'initialisation du mot de passe a bel et bien été envoyé à l\'utilisateur ' +
                                                        this.groupeDeControle.controls.identifiant.value + '.');
      },
      (erreur: string) => this.traitementErreurs.traitementErreur(erreur)

    );
  }

  getPrivilegeOtp() {
    if(this.privileges) {
      for (const privilege of this.privileges) {
        if (privilege.type === "otp") {
          return privilege;
        }
      }
    }
    return null;
  }

  removePrivilegeOtp() {
    var ar = []
    for (const privilege of this.privileges) {
      if (privilege.type !== "otp") {
        ar.push(privilege);
      }
    }
    this.privileges = ar;
  }

  isInitOtpDisabled() {
    return this.getPrivilegeOtp() === null;
  }

  reinitialiserOtp() {
    if(!this.isInitOtpDisabled()) {
      this.viderAllMessages();
      const privilege = this.getPrivilegeOtp();
      this.clientHTTPSX5.reinitialiserOtpPOSTHttp(this.groupeDeControle.controls.identifiant.value, privilege.value).subscribe(
        resultat => {
          this.removePrivilegeOtp();
          this.message.ajouterUnMessageALaListeDeMessage('Un courriel de réinitialisation a bel et bien été envoyé à l\'utilisateur ' +
                                                          this.groupeDeControle.controls.identifiant.value + '.');
        },
        (erreur: string) => this.traitementErreurs.traitementErreur(erreur)
      );
    }
  }

  reinitialiserPassword() {
    this.viderAllMessages();
    this.clientHTTPSX5.reinitialiserPasswordPOSTHttp(this.groupeDeControle.controls.identifiant.value).subscribe(
      resultat => {
        this.message.ajouterUnMessageALaListeDeMessage('Un courriel de réinitialisation a bel et bien été envoyé à l\'utilisateur ' +
                                                        this.groupeDeControle.controls.identifiant.value + '.');
      },
      (erreur: string) => this.traitementErreurs.traitementErreur(erreur)
    );
  }
  
  confirmationRetraitCompte() {
    this.viderAllMessages();
    this.dialogue.open(DesactivationCompteUtilisateurComponent, {
      data: {identifiant: this.groupeDeControle.controls.identifiant.value}
    });
  }

  private viderAllMessages() {
    this.message.viderMessage();
    this.traitementErreurs.viderErreurs();
  }

  verrouillerCompte() {
    if (this.groupeDeControle.controls.actif.value) {
      this.groupeDeControle.controls.actif.setValue(false);
    } else {
      this.groupeDeControle.controls.actif.setValue(true);
    }
    this.modifierInformationsUtilisateur();
  }

  private obtenirInformationsUtilisateur() {
    this.clientHTTPSX5.obtenirUtilisateurParIdentifiantGETHttp(this.groupeDeControle.controls.identifiant.value)
      .subscribe(
        resultat => this.enregistrerInformationsUtilisateur(resultat),
        (erreur: string) => this.traitementErreurs.traitementErreur(erreur)
      );
  }
}

@Component({
  selector: 'app-desactivation-compte-utilisateur',
  templateUrl: './desactivation-compte-utilisateur.component.html',
  styleUrls: ['./desactivation-compte-utilisateur.component.css']
})
export class DesactivationCompteUtilisateurComponent implements OnInit {

  debloque = false;

  constructor(public referenceDialogue: MatDialogRef<DesactivationCompteUtilisateurComponent>,
              @Inject(MAT_DIALOG_DATA) public donnees: donneesDialogue,
              private clientHTTPSx5: HttpClientSx5,
              public traitementErreurs: TraitementErreurService,
              private routeur: Router,
              private message: MessageService ) {}

  ngOnInit() {
    this.traitementErreurs.interrupteurDialogue();
  }

  retirerUtilisateur() {
    this.clientHTTPSx5.retirerCompteUtilisateurDuDepotDELETEHttp(this.donnees.identifiant).subscribe(
      () => {
        this.message.mettreUnMessage('Retrait du compte ' + this.donnees.identifiant + ' effectué avec succès.');
        this.retournerAuTableau();
      },
      (erreur: string) => {
        this.traitementErreurs.traitementErreur(erreur)}
    );
  }

  retournerAuTableau() {
    this.fermerDialogue();
    return this.routeur.navigate(['']);
  }

  fermerDialogue() {
    this.traitementErreurs.interrupteurDialogue();
    this.referenceDialogue.close();
  }

}
