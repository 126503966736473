<section id="main">

  <div [ngClass]="{ 'messageErreur': this.traitementErreurs.nombreErreurs() != 0 }">
    <li *ngFor="let erreur of this.traitementErreurs.obtenirListeErreurs()">
      {{erreur}}
    </li>
  </div>

  <br>

  <div [ngClass]="{ 'messageSucces': this.message.nombreMessages() != 0 }">
    <li *ngFor="let message of this.message.obtenirListeMessages()">
      {{message}}
    </li>
  </div>

  <mat-expansion-panel expanded>
      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>
          <b>Identifiant</b>
        </mat-label>
        <input matInput #identifiantHTML id="identifiantInput" (keyup.enter)="faireRecherche(identifiantHTML, prenomHTML, nomHTML, courrielHTML)">
      </mat-form-field>
  
      <div class="separateurEntreBouton"></div>
  
      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>
          <b>Prénom</b>
        </mat-label>
        <input matInput #prenomHTML id="prenomInput" (keyup.enter)="faireRecherche(identifiantHTML, prenomHTML, nomHTML, courrielHTML)">
      </mat-form-field>
  
      <div class="separateurEntreBouton"></div>
  
      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>
          <b>Nom</b>
        </mat-label>
        <input matInput #nomHTML id="nomInput" (keyup.enter)="faireRecherche(identifiantHTML, prenomHTML, nomHTML, courrielHTML)">
      </mat-form-field>
  
      <div class="separateurEntreBouton"></div>
  
      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>
          <b>Courriel</b>
        </mat-label>
        <input matInput #courrielHTML id="courrielInput" (keyup.enter)="faireRecherche(identifiantHTML, prenomHTML, nomHTML, courrielHTML)">
      </mat-form-field>
  
      <mat-action-row>
        <button mat-raised-button id="boutonRechercher" class="boutonRecherche" [disabled]="!this.auMoinsUnCritereDeRechercheEstSaisi(identifiantHTML, prenomHTML, nomHTML, courrielHTML)" (click)="faireRecherche(identifiantHTML, prenomHTML, nomHTML, courrielHTML)">Rechercher</button>
        <div class="separateurEntreBoutonRecherche"></div>
        <button mat-raised-button class="boutonRecherche" id="boutonReinitialiser" (click)="reinitialiser(identifiantHTML, prenomHTML, nomHTML, courrielHTML)"> Réinitialiser </button>
      </mat-action-row>
    </mat-expansion-panel>

    <br>

  <button mat-raised-button class="boutonTable" (click)="allerAuFormulaireAjoutUtilisateur()">Ajouter</button>
  <button mat-raised-button class="boutonTable boutonModifier" [disabled]="!selection.hasValue()" (click)="allerAuFormulaireBoutonModifier()">Modifier</button>


  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="sourceDeDonnees">

      <!-- Position Column -->
      <ng-container matColumnDef="identifiant">
        <th mat-header-cell *matHeaderCellDef> Identifiant </th>
        <td mat-cell *matCellDef="let row; let element" [ngClass]="{ 'selected': selection.isSelected(row)}"> {{element.identifiant}} </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="prenom">
        <th mat-header-cell *matHeaderCellDef> Prénom </th>
        <td mat-cell *matCellDef="let row; let element" [ngClass]="{ 'selected': selection.isSelected(row)}"> {{element.prenom}} </td>
      </ng-container>

      <!-- Weight Column -->
      <ng-container matColumnDef="nom">
        <th mat-header-cell *matHeaderCellDef> Nom </th>
        <td mat-cell *matCellDef="let row; let element" [ngClass]="{ 'selected': selection.isSelected(row)}"> {{element.nom}} </td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="courriel">
        <th mat-header-cell *matHeaderCellDef> Courriel </th>
        <td mat-cell *matCellDef="let row; let element" [ngClass]="{ 'selected': selection.isSelected(row)}"> {{element.courriel}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{ 'selected': selection.isSelected(row)}" (click)="selectionnerUtilisateur(row)"
        (dblclick)="allerAuFormulaireDoubleClique(row)"></tr>
    </table>

    <mat-paginator        
      [length]="nombreComptesTotal" 
      [pageSize]='obtenirNombreComptesParPage()' 
      [pageSizeOptions]=[5,10,20,50,100] 
      (page)="changementDePage($event)">
    </mat-paginator>
  </div>


  <button mat-raised-button class="boutonTable" (click)="allerAuFormulaireAjoutUtilisateur()">Ajouter</button>
  <button mat-raised-button class="boutonTable" [disabled]="!selection.hasValue()" (click)="allerAuFormulaireBoutonModifier()">Modifier</button>

</section>