<mat-accordion>
  <mat-expansion-panel class="col-md-12 sansPaddingGaucheEtDroite">
        <mat-expansion-panel-header>
            <mat-panel-title>
                Coordonnées
            </mat-panel-title>
        </mat-expansion-panel-header>
    <div class="form-group">
        <form class="example-form" [formGroup]="userAddressValidations">            
          <mat-form-field appearance="outline" class="col-sm-8" floatLabel="always">
            <mat-label># Téléphone au travail</mat-label>
            <input matInput formControlName="mobileNumber" maxlength="16" (keyup)=obtenirCoordonneesSaisies($event) (keydown)=obtenirCoordonneesSaisies($event)
            value="{{formatMobileNumber}}">
            <mat-icon matSuffix>phone</mat-icon>
            <mat-hint align="start">(###) ###-####</mat-hint>
            <mat-error *ngIf="userAddressValidations.get('mobileNumber').hasError('pattern') || this.telephoneObligatoire==true">
              Le numéro de téléphone n'est pas valide
            </mat-error>
          </mat-form-field>
          
          <mat-form-field appearance="outline" class="col-sm-4" floatLabel="always">
            <mat-label>Extension</mat-label>
            <input matInput formControlName="extensionMobileNumber" maxlength="10" (keyup)=obtenirCoordonneesSaisies($event)>
            <mat-error *ngIf="userAddressValidations.get('extensionMobileNumber').hasError('pattern')">
              Le numéro d'extension n'est pas valide
            </mat-error>
          </mat-form-field> 

          <div class="col-sm-12"><div class="separateur"></div></div>

          <mat-form-field appearance="outline" class="col-sm-8" floatLabel="always">
            <mat-label>Cellulaire professionnel</mat-label>
            <input matInput formControlName="cellulaireNumber" maxlength="16" (keyup)=obtenirCoordonneesSaisies($event) (keydown)=obtenirCoordonneesSaisies($event)
            value="{{formatCellulaireNumber}}">
            <mat-icon matSuffix>phone_iphone</mat-icon>
            <mat-hint align="start"><strong>(###) ###-####</strong> </mat-hint>
            <mat-error *ngIf="userAddressValidations.get('cellulaireNumber').hasError('pattern')">
              Le numéro cellulaire n'est pas valide
            </mat-error>
          </mat-form-field>
          <div class="col-sm-4"></div>

          <div class="col-sm-12"><div class="separateur"></div></div>
          
          <mat-form-field appearance="outline" class="col-sm-12" floatLabel="always">
            <mat-label>Lieu de travail</mat-label>
            <input matInput formControlName="lieuTravail" maxlength="255">
            <mat-icon matSuffix>location_on</mat-icon>
          </mat-form-field>
        </form>
    </div>
  </mat-expansion-panel>
</mat-accordion>
