import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class MessageService {
  private messages: string[] = [];
  private message: string = "";

  ajouterUnMessageALaListeDeMessage(message: string) {
    this.messages.push(message);
  }

  mettreUnMessage(message:string) {
    this.message = message;
  }

  viderMessage() {
    this.messages = [];
    this.message = "";
  }

  obtenirListeMessages() {
    return this.messages;
  }

  obtenirMessage() {
    return this.message;
  }

  nombreMessages(): number {
    return this.messages.length;
  }
}
