import { Injectable } from '@angular/core';

const START_INDEX_PAR_DEFAUT = 1;
const NOMBRE_COMPTES_PAR_PAGE_PAR_DEFAUT = 20;
const INDEX_DE_PAGE_PAR_DEFAUT = 0;
@Injectable({
  providedIn: 'root'
})
export class PaginationService {

  identifiant = '';
  prenom = '';
  nom = '';
  courriel = '';
  startIndex: number = START_INDEX_PAR_DEFAUT;
  nombreComptesParPage: number = NOMBRE_COMPTES_PAR_PAGE_PAR_DEFAUT;
  indexDePage: number = INDEX_DE_PAGE_PAR_DEFAUT;

  constructor() { }

  obtenirStartIndex(): number {
    return this.startIndex;
  }

  obtenirNombreComptesParPage(): number {
    return this.nombreComptesParPage;
  }

  obtenirIndexDePage(): number {
    return this.indexDePage;
  }

  obtenirIdentifiant(): string {
    return this.identifiant;
  }

  obtenirPrenom(): string {
    return this.prenom;
  }

  obtenirNom(): string {
    return this.nom;
  }

  obtenirCourriel(): string {
    return this.courriel;
  }

  changerStartIndex(valeur: number) {
    this.startIndex = valeur;
  }

  changerNombreComptesParPage(valeur: number) {
    this.nombreComptesParPage = valeur;
  }

  changementIndexDePage(valeur: number) {
    this.indexDePage = valeur;
  }

  changementIdentifiant(valeur: string) {
    this.identifiant = valeur;
  }

  changementPrenom(valeur: string) {
    this.prenom = valeur;
  }

  changementNom(valeur: string) {
    this.nom = valeur;
  }

  changementCourriel(valeur: string) {
    this.courriel = valeur;
  }


  reinitialiserPagination() {
    this.startIndex = START_INDEX_PAR_DEFAUT;
    this.indexDePage = INDEX_DE_PAGE_PAR_DEFAUT;
  }

  reinitialiserChampsRecherche() {
    this.changementIdentifiant('');
    this.changementPrenom('');
    this.changementNom('');
    this.changementCourriel('');
  }


  auMoinsUnCritereDeRechercheEstAlimente(): boolean {
    return (this.obtenirIdentifiant() !== '' ||
    this.obtenirPrenom() !== '' ||
    this.obtenirNom() !== '' ||
    this.obtenirCourriel() !== '');
  }
}
