import { Component, OnInit } from '@angular/core';
import { GroupsEntity } from '../services/userScim';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { HttpClientSx5 } from '../services/client-http-sx5/http.client.sx5';
import { TraitementErreurService } from '../services/traitement-erreurs/traitement-erreur.service';

@Component({
  selector: 'app-association-groupes',
  templateUrl: './association-groupes.component.html',
  styleUrls: ['./association-groupes.component.css']
})
export class AssociationGroupesComponent implements OnInit {

  constructor(private clientHTTPSX5: HttpClientSx5, public traitementErreurs: TraitementErreurService) {
    this.groupeDeControle = new UntypedFormGroup({
      listeGroupe : new UntypedFormControl("", []),
      listeGroupeAffecte : new UntypedFormControl("", [])
    });
  }

  roupeDeControle: UntypedFormGroup;
  listeGroupes: GroupsEntity[] = [];
  listeGroupesAffectes: GroupsEntity[] = [];
  groupeDeControle: UntypedFormGroup;
  panelOpenState = false;

  ngOnInit() {
    this.remplirListeGroupe();

  }

  remplirListeGroupe(){
    this.obtenirListeDeGroupes();
    this.listeGroupesAffectes.sort((a, b) => a.value.localeCompare(b.value));
    this.listeGroupes.sort((a, b) => a.value.localeCompare(b.value));
  }

  getGroupe():Array<any>{
    return this.groupeDeControle.controls.listeGroupe.value;
  }
  getGroupeAffecte():Array<any>{
    return this.groupeDeControle.controls.listeGroupeAffecte.value;
  }

  associerGroupe(){
    var groupes = this.getGroupe();
    var groupesLocal: GroupsEntity[] = [];
    for(var i:number=0; i<groupes.length; i++){
      var groupe: GroupsEntity = new GroupsEntity();
        groupe.value = groupes[i];
        groupesLocal.push(groupe);
    }
    this.associerGroupeDeListe(groupesLocal);
  }

  associerGroupeDeListe(groupe:Array<any>){
    for(var i:number=0; i<groupe.length; i++){
      var groupeReturn: GroupsEntity =  this.listeGroupes.find(x => x.value == groupe[i].value);
      if(groupeReturn!=undefined){
        this.listeGroupesAffectes.push(groupeReturn);
        this.listeGroupesAffectes.sort((a, b) => a.value.localeCompare(b.value));
        this.groupeDeControle.controls.listeGroupeAffecte.setValue(groupeReturn.value);
        this.effacerGroupeDeListeDeGroupes(groupeReturn);
      }
    }
  }

  effacerGroupeDeListeDeGroupes(groupe:GroupsEntity){
    const index = this.listeGroupes.indexOf(groupe, 0);
    if (index > -1) {
      this.listeGroupes.splice(index, 1);
    }
  }

  desassocierGroupe(){
    this.desassocierGrupeDeGroupesAssocies(this.getGroupeAffecte());
  }

  desassocierGrupeDeGroupesAssocies(groupe:Array<any>){
    for(var i:number=0; i<groupe.length; i++){
      var groupeReturn: GroupsEntity =  this.listeGroupesAffectes.find(x => x.value == groupe[i]);
      if(groupeReturn!=undefined){
        this.listeGroupes.push(groupeReturn);
        this.listeGroupes.sort((a, b) => a.value.localeCompare(b.value));
        this.groupeDeControle.controls.listeGroupe.setValue(groupeReturn.value);
        this.effacerGroupeDeListeDeGroupesAssocies(groupeReturn);
      }
    }
  }

  effacerGroupeDeListeDeGroupesAssocies(groupe:GroupsEntity){
    const index = this.listeGroupesAffectes.indexOf(groupe, 0);
    if (index > -1) {
      this.listeGroupesAffectes.splice(index, 1);
    }
  }

  affecterGroupes(result:any){
    if(result.groups!=undefined){
        this.associerGroupeDeListe(result.groups);
    }
  }

  enregistrerListeDeGroupes(result:any) {
    if(result.Resources!=undefined){
      this.listeGroupes = [];
      this.listeGroupesAffectes = [];
      for(var i:number=0; i<result.Resources.length; i++){
        var groupe: GroupsEntity = new GroupsEntity();
        groupe.value = result.Resources[i].value;
        this.listeGroupes.push(groupe);
      }
    }
    this.listeGroupes.sort((a, b) => a.value.localeCompare(b.value));
  };

  private obtenirListeDeGroupes() {
    this.clientHTTPSX5.obtenirListeDeGroupesGETHttp()
      .subscribe(
        resultat => this.enregistrerListeDeGroupes(resultat),
        (erreur: string) => this.traitementErreurs.traitementErreur(erreur)
      );
  }
}
