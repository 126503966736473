export class UserScim {
  userName: string;
  name: Name;
  emails?: (EmailsEntity)[] | null;
  active: boolean;
  groups?: (GroupsEntity)[] | null;
  phoneNumbers?: (PhoneNumbersEntity)[] | null;
  ["urn:ietf:params:scim:schemas:extension:enterprise:2.0:User"]: Entreprise;
  ["urn:sx5:scim:api:messages:2.0:UserExtention"]: UserExtention;
}
export class Name {
  givenName: string;
  familyName: string;
}
export class EmailsEntity {
  value: string;
}
export class GroupsEntity {
  value: string;
}
export class PhoneNumbersEntity {
  value: string;
  type: string;
}
export class Entreprise {
  organization: string;
}
export class UserExtention {
  accountExpires: string;
}