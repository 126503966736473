<h1 align="center">Ajout d'un nouveau compte d'utilisateur</h1>

<div class="formulaire">

    <div [ngClass]="{ 'messageErreur': this.traitementErreurs.nombreErreurs() != 0 }">
        <li *ngFor="let erreur of this.traitementErreurs.obtenirListeErreurs()">
            {{erreur}}
        </li>
    </div>
    
  <mat-form-field appearance="outline" floatLabel="always">
    <mat-label>
      <b>Prénom</b>
    </mat-label>
    <input matInput #prenomHTML (keyup.enter)="ajouterUtilisateur(prenomHTML, nomHTML, courrielHTML)"
       pattern="^(?!\s*$).+" [formControl]="this.controleAjoutPrenom()">
  </mat-form-field>

  <mat-form-field appearance="outline" floatLabel="always">
    <mat-label>
      <b>Nom</b>
    </mat-label>
    <input matInput #nomHTML (keyup.enter)="ajouterUtilisateur(prenomHTML, nomHTML, courrielHTML)"
       pattern="^(?!\s*$).+" [formControl]="this.controleAjoutNom()">
  </mat-form-field>

  <mat-form-field appearance="outline" floatLabel="always">
    <mat-label>
      <b>Courriel</b>
    </mat-label>
    <input matInput type="email" #courrielHTML (keyup.enter)="ajouterUtilisateur(prenomHTML, nomHTML, courrielHTML)"
       pattern='^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$' [formControl]="this.controleAjoutCourriel()">
  </mat-form-field>

  <app-coordonnees></app-coordonnees>
  <div class="separateur"></div>
  <app-association-groupes></app-association-groupes>
  <div class="separateur"></div>
  <div class="separateur"></div>


  <div class="bouton">
    <button mat-raised-button (click)="ajouterUtilisateur(prenomHTML, nomHTML, courrielHTML)"
    [disabled]="this.groupeDeControle.invalid || this.coordonneesComponent.userAddressValidations.invalid">Sauvegarder</button>
    <div class="separateurEntreBouton"></div>
    <button mat-raised-button class="boutonAnnuler" (click)="retournerAuTableauSansCreation()">Annuler</button>
  </div>
</div>