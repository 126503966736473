import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './not-found.component';
import { TableDepotUtilisateurs } from './liste-paginee/table-depot-utilisateurs';
import { formulaireModification } from './formulaireModification/formulaireModification';
import { AjoutUtilisateurComponent } from './ajout-utilisateur/ajout-utilisateur.component';
import { AppAuthGuard } from './app.authguard';
import { InterdictionComponent } from './services/interdiction/interdiction.component';
import { APP_BASE_HREF } from '@angular/common';

const routes: Routes = [
    { path: '',
    redirectTo: 'tableau',
    pathMatch: 'full' },
    {
        path: 'tableau',
        component: TableDepotUtilisateurs,
        canActivate: [AppAuthGuard]
    },
    {
        path: 'formulaire/:identifiant',
        component: formulaireModification,
        canActivate: [AppAuthGuard]
    },
    {
        path: 'nouveau',
        component: AjoutUtilisateurComponent,
        canActivate: [AppAuthGuard]
    },
    { path: 'interdiction', component: InterdictionComponent },
    { path: '**', component: PageNotFoundComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule],
    providers: [{provide: APP_BASE_HREF, useValue : '/' }]
})
export class AppRoutingModule { }