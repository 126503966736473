import { Component, OnInit} from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder} from '@angular/forms';
import { PhoneNumbersEntity } from '../services/userScim';
import { parse, format, AsYouType } from 'libphonenumber-js';

@Component({
  selector: 'app-coordonnees',
  templateUrl: './coordonnees.component.html',
  styleUrls: ['./coordonnees.component.css']
})
export class CoordonneesComponent implements OnInit {

  userAddressValidations: UntypedFormGroup;
  phoneNumbersEntityList: PhoneNumbersEntity[] = [];
  telephoneObligatoire: boolean = false;
  formatMobileNumber: string = "";
  formatCellulaireNumber: string = "";
  parse1: any;
  parse2: any;

  
  constructor(private formBuilder: UntypedFormBuilder) {
    this.userAddressValidations = this.formBuilder.group({
      mobileNumber: ['', [Validators.pattern(/^$|^(1\s)*\(?\d{3}\)?[\s-]\d{3}[\s-]\d{4}$/)]],
      extensionMobileNumber:  ['', [Validators.pattern(/^$|^([0-9]\d*)$/)]],
      cellulaireNumber: ['', [Validators.pattern(/^$|^(1\s)*\(?\d{3}\)?[\s-]\d{3}[\s-]\d{4}$/)]],
      lieuTravail: ['', []]
    });
  }

  ngOnInit() {
  }

  obtenirCoordonneesSaisies(event:any): PhoneNumbersEntity[] {
    this.formatterVariables();
    this.formatterTelephones();
    this.userAddressValidations.controls.mobileNumber.markAsTouched();
    this.userAddressValidations.controls.extensionMobileNumber.markAsTouched();
    this.userAddressValidations.controls.cellulaireNumber.markAsTouched();
    if(!this.userAddressValidations.invalid){
      return this.remplirCoordonnees();
    }else if(this.userAddressValidations.controls.mobileNumber.value == "" && this.userAddressValidations.controls.extensionMobileNumber.value == ""){
      this.nettoyerErreursForm();
    }
    if(event.code == "Backspace"){
      var value:string = "";
      if(this.userAddressValidations.controls.mobileNumber.value != "" && this.userAddressValidations.controls.mobileNumber.value.substring(0,1) == "1" && this.userAddressValidations.controls.mobileNumber.value.length==7){
        value = this.userAddressValidations.controls.mobileNumber.value;
        this.userAddressValidations.controls.mobileNumber.setValue(value.substring(0,6));
      }else if(this.userAddressValidations.controls.mobileNumber.value != "" && this.userAddressValidations.controls.mobileNumber.value.length==5){
        value = this.userAddressValidations.controls.mobileNumber.value;
        this.userAddressValidations.controls.mobileNumber.setValue(value.substring(0,4));
      }else if(this.userAddressValidations.controls.cellulaireNumber.value != "" && this.userAddressValidations.controls.cellulaireNumber.value.substring(0,1) == "1" && this.userAddressValidations.controls.cellulaireNumber.value.length==7){
        value = this.userAddressValidations.controls.cellulaireNumber.value;
        this.userAddressValidations.controls.cellulaireNumber.setValue(value.substring(0,6));
      }else if(this.userAddressValidations.controls.cellulaireNumber.value != "" && this.userAddressValidations.controls.cellulaireNumber.value.length==5){
        value = this.userAddressValidations.controls.cellulaireNumber.value;
        this.userAddressValidations.controls.cellulaireNumber.setValue(value.substring(0,4));
      }
    }
  }

  public formatterVariables(){
    this.formatMobileNumber = new AsYouType('CA').input(this.userAddressValidations.controls.mobileNumber.value);
    this.formatCellulaireNumber = new AsYouType('CA').input(this.userAddressValidations.controls.cellulaireNumber.value);
  }

  public formatterTelephones(){
    this.userAddressValidations.controls.mobileNumber.setValue(new AsYouType('CA').input(this.userAddressValidations.controls.mobileNumber.value));
    this.userAddressValidations.controls.cellulaireNumber.setValue(new AsYouType('CA').input(this.userAddressValidations.controls.cellulaireNumber.value));
    this.userAddressValidations.controls.extensionMobileNumber.setValue(this.retirerCaracteresIllegauxAExtension(this.userAddressValidations.controls.extensionMobileNumber.value));
  }

  public deformatterPhones(numero:string): string{
    return parse(numero, 'CA').phone.valueOf();
  }

  public remplirCoordonnees():PhoneNumbersEntity[]{
    this.phoneNumbersEntityList = [];
    var phoneNumbersEntity: PhoneNumbersEntity;
    if (this.userAddressValidations.controls.mobileNumber.value !== undefined && this.userAddressValidations.controls.mobileNumber.value !== "") {
      phoneNumbersEntity = new PhoneNumbersEntity();
      phoneNumbersEntity.type = "work";
      phoneNumbersEntity.value = this.deformatterPhones(this.userAddressValidations.controls.mobileNumber.value);
      if (this.userAddressValidations.controls.extensionMobileNumber.value !== undefined && this.userAddressValidations.controls.extensionMobileNumber.value !== "") {
        phoneNumbersEntity.value = phoneNumbersEntity.value + "" + this.userAddressValidations.controls.extensionMobileNumber.value;
      }
      this.phoneNumbersEntityList.push(phoneNumbersEntity);
    }
    if (this.userAddressValidations.controls.cellulaireNumber.value !== undefined && this.userAddressValidations.controls.cellulaireNumber.value !== "") {
      phoneNumbersEntity = new PhoneNumbersEntity();
      phoneNumbersEntity.type = "mobile";
      phoneNumbersEntity.value = this.deformatterPhones(this.userAddressValidations.controls.cellulaireNumber.value);
      this.phoneNumbersEntityList.push(phoneNumbersEntity);
    }
    this.validerDependenceTelephExtension();
    return this.phoneNumbersEntityList;
  }

  private validerDependenceTelephExtension(){
    if(this.userAddressValidations.controls.mobileNumber.value == "" && this.userAddressValidations.controls.extensionMobileNumber.value != ""){
      this.telephoneObligatoire = true;
      this.userAddressValidations.controls.mobileNumber.setErrors({parsedDomain: ""});
      this.userAddressValidations.controls.mobileNumber.markAsTouched();
    }else if(this.userAddressValidations.controls.mobileNumber.value == "" && this.userAddressValidations.controls.extensionMobileNumber.value == ""){
      this.nettoyerErreursForm();
    }
  }

  private nettoyerErreursForm(){
    this.telephoneObligatoire = false;
    this.userAddressValidations.controls.mobileNumber.setErrors(null);
  }

  affecterCoordonnees(result:any){
    if(result.phoneNumbers!=undefined){
      var phoneNumbersEntity:PhoneNumbersEntity[] = [];
      Object.assign(phoneNumbersEntity, result.phoneNumbers);
      for(var i:number=0; i<phoneNumbersEntity.length; i++){
        if(phoneNumbersEntity[i].type!=="" && phoneNumbersEntity[i].type==="work" && phoneNumbersEntity[i].value!==""){
          this.userAddressValidations.controls.mobileNumber.setValue(this.telephoneFormate(phoneNumbersEntity[i].value.substr(0,10)));
          if(phoneNumbersEntity[i].value.length>10){
            this.userAddressValidations.controls.extensionMobileNumber.setValue(phoneNumbersEntity[i].value.substr(10));
          }
        }else if(phoneNumbersEntity[i].type!=="" && phoneNumbersEntity[i].type==="mobile" && phoneNumbersEntity[i].value!==""){
          this.userAddressValidations.controls.cellulaireNumber.setValue(this.telephoneFormate(phoneNumbersEntity[i].value));
        }
      }
    }
    if(result["urn:ietf:params:scim:schemas:extension:enterprise:2.0:User"]!=undefined && result["urn:ietf:params:scim:schemas:extension:enterprise:2.0:User"]!=""){
      this.userAddressValidations.controls.lieuTravail.setValue(result["urn:ietf:params:scim:schemas:extension:enterprise:2.0:User"].organization);
    }
  }

  public telephoneFormate(telephoneDeformate:string){
    return new AsYouType('CA').input(telephoneDeformate);
  }

  private retirerCaracteresIllegauxAExtension(extension:string) {
    return extension.replace(/\D/g, '');
  }
}