import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClientSx5 } from '../services/client-http-sx5/http.client.sx5';
import { Router } from '@angular/router';
import { MessageService } from '../services/messages/message.service';
import { TraitementErreurService } from '../services/traitement-erreurs/traitement-erreur.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { PaginationService } from '../services/pagination/pagination.service';
import { GroupsEntity } from '../services/userScim';
import { AssociationGroupesComponent } from '../association-groupes/association-groupes.component';
import { CoordonneesComponent } from '../coordonnees/coordonnees.component';

@Component({
  selector: 'app-ajout-utilisateur',
  templateUrl: './ajout-utilisateur.component.html',
  styleUrls: ['./ajout-utilisateur.component.css']
})
export class AjoutUtilisateurComponent implements OnInit {
  identifiant: string;
  private motDePasse: string;
  groupeDeControle: UntypedFormGroup;
  @ViewChild(AssociationGroupesComponent, {static: true}) associationGroupesComponent: AssociationGroupesComponent;
  @ViewChild(CoordonneesComponent, {static: true}) coordonneesComponent: CoordonneesComponent;

  constructor(private clientHTTPSX5: HttpClientSx5, 
              private routeur: Router, 
              public message: MessageService, 
              public traitementErreurs: TraitementErreurService,
              public pagination: PaginationService) {
          this.groupeDeControle = new UntypedFormGroup({      
            prenom    : new UntypedFormControl('', [Validators.required]),
            nom       : new UntypedFormControl('', [Validators.required]),
            courriel  : new UntypedFormControl('', [Validators.required])     
          });
  }

  ngOnInit() {
    this.traitementErreurs.viderErreurs();    
  }

  

  retournerAuTableauAvecAjout(): Promise<boolean> {
    this.message.viderMessage();
    this.message.ajouterUnMessageALaListeDeMessage("Ajout du compte " + this.identifiant + " effectué avec succès.");
    this.message.ajouterUnMessageALaListeDeMessage("Un courriel d'initialisation du mot de passe a bel et bien été envoyé.");
    this.reinitializerRecherche();
    return this.routeur.navigate(['']);
  }

  reinitializerRecherche(){
    this.pagination.changementIdentifiant("");
    this.pagination.changementPrenom("");
    this.pagination.changementNom("");
    this.pagination.changementCourriel("");
  }

  ajouterUtilisateur(prenom, nom, courriel) {
    if(this.champsDumentRemplis()){
      this.clientHTTPSX5.ajouterUtilisateurPOSTHttp( this.groupeDeControle.controls.prenom.value, 
                                                    this.groupeDeControle.controls.nom.value, 
                                                    this.groupeDeControle.controls.courriel.value, 
                                                    this.associationGroupesComponent.listeGroupesAffectes,
                                                    this.coordonneesComponent.phoneNumbersEntityList,
                                                    this.coordonneesComponent.userAddressValidations.controls.lieuTravail.value).subscribe(
        (resultat) => {
                      this.motDePasse = resultat.headers.get('MotDePasse');
                      this.identifiant = resultat.body.userName;
                      this.retournerAuTableauAvecAjout();
        },
        (erreur: string) => this.traitementErreurs.traitementErreur(erreur)
      );
    }
  }

  champsDumentRemplis(){
    this.traitementErreurs.viderErreurs();
    var valide:boolean = true;
    if(!this.groupeDeControle.controls.prenom.valid){
      valide = this.obligatorieteDesChamps("Le prénom est obligatoire");
    }
    if(!this.groupeDeControle.controls.nom.valid){
      valide = this.obligatorieteDesChamps("Le nom est obligatoire");
    }
    if(!this.groupeDeControle.controls.courriel.valid){
      valide = this.obligatorieteDesChamps("Le courriel fourni doit être un courriel valide");
    }
    return valide;
  }

  private obligatorieteDesChamps(message: string) {
    this.traitementErreurs.affecterDesErreurs(message);
    var valide = false;
    return valide;
  }

  

  retournerAuTableauSansCreation(): Promise<boolean> {
    this.message.viderMessage();
    this.traitementErreurs.viderErreurs();
    return this.routeur.navigate(['']);
  }

  controleAjoutPrenom(){
    return this.groupeDeControle.controls.prenom;
  }

  controleAjoutNom(){
    return this.groupeDeControle.controls.nom;
  }

  controleAjoutCourriel(){
    return this.groupeDeControle.controls.courriel;
  }
}
