export function configLog(level:string){
    switch (level) {
        case 'ALL':
            // print everything
            break;
        case 'DEBUG':
            break;
        case 'INFO':
            console.debug = function () {};
            break;
        case 'LOG': 
            console.debug = function () {};
            console.info = function () {};
            break
        case 'WARN':
            console.debug = function () {};
            console.log = function () {};
            console.info = function () {};
            break;
        case 'ERROR':
            console.debug = function () {};
            console.log = function () {};
            console.warn = function () {};
            console.info = function () {};
            break;
        case 'OFF':
            console.debug = function () {};
            console.log = function () {};
            console.error = function () {};
            console.warn = function () {};
            console.info = function () {};
            break;
        default:
            break;
    }
}