<mat-accordion>
  <mat-expansion-panel class="col-md-12 sansPaddingGaucheEtDroite">
        <mat-expansion-panel-header>
            <mat-panel-title>
                Groupes
            </mat-panel-title>
        </mat-expansion-panel-header>
    <div class="form-group">
        <div class="col-md-6">
            <label for="sel1">Liste de groupes:</label>
            <select multiple class="form-control" [formControl]="this.groupeDeControle.controls.listeGroupe" id="sel1" size="10">
                <option *ngFor="let groupe of listeGroupes" [value]="groupe.value">
                    {{groupe.value}}
                </option>
            </select>
            <br>
            <button [disabled]="listeGroupes.length == 0" class="btn btn-default ng-binding" type="submit" (click)="associerGroupe()" id="associerGroupe">
            Joindre ce(s) groupe(s) <span class="glyphicon glyphicon-forward"></span>
            </button>
        </div>
        <div class="col-md-6">
            <label for="sel2">Groupes associés:</label>
            <select multiple class="form-control" [formControl]="this.groupeDeControle.controls.listeGroupeAffecte" id="sel2" size="10">
                    <option *ngFor="let groupe of listeGroupesAffectes" [value]="groupe.value">
                        {{groupe.value}}
                    </option>
                </select>
            <br>
            <button [disabled]="listeGroupesAffectes.length == 0" class="btn btn-default ng-binding" type="submit" (click)="desassocierGroupe()" id="desassocierGroupe">
                <span class="glyphicon glyphicon-backward"></span>
                Retirer ce(s) groupe(s)
            </button>
        </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>
