export const environment = {
  production: true,
  keycloakUrlAccessToken: '/auth/realms/{REALM}/protocol/openid-connect/token',
  keycloakRootUrl: 'https://gia.sx5.rtss.qc.ca',
  keycloakrealm: 'msss',
  keycloakclientid: 'sx5duuiprod',
  keycloakclientsecret: 'KEYCLOAK_CLIENT_SECRET',
  keycloakcheckLoginIframe: true,
  isAccountExpiresActive: true,
  sx5ServiceUrl: 'https://depot-services.sx5.rtss.qc.ca',
  angularProtectUrl: 'https://depot-utilisateur.sx5.rtss.qc.ca',
  theme: 'msss',
  logLevel: 'ERROR'
};
