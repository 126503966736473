import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { UtilisateurSCIM } from '../../shared/utils/user-scim';
import { SelectionModel } from '@angular/cdk/collections';
import { Router } from '@angular/router';
import { PaginationService } from '../services/pagination/pagination.service';
import { MessageService } from '../services/messages/message.service';
import { HttpClientSx5 } from '../services/client-http-sx5/http.client.sx5';
import { TraitementErreurService } from '../services/traitement-erreurs/traitement-erreur.service';
import { Subscription } from 'rxjs';

const PREMIER_COURRIEL_DEFAUT = 0;
const VIDE = 0;
const MESSAGE_NON_RESULTATS = 'La recherche n\'a retourné aucun résultat.';
const MESSAGE_AFFINER_RECHERCHE = 'La recherche a retourné plus de résultats : veuillez affiner votre recherche.';

/**
 * @title Table with pagination
 */
@Component({
  selector: 'table-depot-utilisateurs',
  styleUrls: ['table-depot-utilisateurs.css'],
  templateUrl: 'table-depot-utilisateurs.html',
})
export class TableDepotUtilisateurs implements OnInit {

  tableauDonnees: UtilisateurSCIM[] = [];
  displayedColumns = ['identifiant', 'prenom', 'nom', 'courriel'];
  sourceDeDonnees: MatTableDataSource<UtilisateurSCIM>;
  selection = new SelectionModel<UtilisateurSCIM>(false, null);
  messageSuccesChangement = '';

  pageEvent: PageEvent;

  @Input()
  nombreComptesTotal = 0;

  @Output()
  page: EventEmitter<PageEvent>;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  @Input('matTooltipDisabled')
  disabled: boolean;

  requeteAuBackend: Subscription;

  constructor(private clientHTTPSX5: HttpClientSx5,
              private router: Router,
              public pagination: PaginationService,
              public message: MessageService,
              public traitementErreurs: TraitementErreurService) {
  }

  changementDePage(event: PageEvent) {
    try {
      this.requeteAuBackend.unsubscribe();
    } catch(erreur) {

    }

    this.pageEvent = event;

    this.actualiserPagination(event);
    this.obtenirUtilisateurs();
    this.viderMessages();
  }

  ngOnInit(): Promise<any> {
    this.traitementErreurs.viderErreurs();
    this.paginator.pageIndex = this.pagination.obtenirIndexDePage();
    this.pagination.reinitialiserChampsRecherche();

    return;
  }

  obtenirUtilisateurs() {
    this.selection.clear();
    this.traitementErreurs.viderErreurs();
    if (this.pagination.auMoinsUnCritereDeRechercheEstAlimente()) {
      this.requeteAuBackend = this.clientHTTPSX5.rechercheCompteUtilisateurPOSHttp(
        this.pagination.obtenirIdentifiant(),
        this.pagination.obtenirPrenom(),
        this.pagination.obtenirNom(),
        this.pagination.obtenirCourriel(),
        this.pagination.obtenirStartIndex(),
        this.pagination.obtenirNombreComptesParPage()
      )
        .subscribe(
          resultat => this.traitementDuResultatObtenu(resultat),
          (erreur) => this.traitementErreurResultatObtenu(erreur)
        );
    }
  }

  private traitementErreurResultatObtenu(erreur: string) {
    this.traitementErreurs.traitementErreur(erreur);
    this.sourceDeDonnees = new MatTableDataSource<UtilisateurSCIM>([]);
  }

  private traitementDuResultatObtenu(resultat: any) {
    if (this.resultatsObtenusNonVide(resultat)) {
      if (resultat.totalResults === 0) {
        this.paginator.pageIndex = 0;
        this.pagination.reinitialiserPagination();
        this.traitementErreurResultatObtenu(MESSAGE_NON_RESULTATS);
      } else {
        if (this.ilFautAffinerLaRequete(resultat.totalResults, this.pagination.obtenirNombreComptesParPage())) {
          this.traitementErreurResultatObtenu(MESSAGE_AFFINER_RECHERCHE);
        }

      }

      this.remplirTableau(resultat);
    }
  }

  remplirTableau(resultat) {
    this.tableauDonnees.length = VIDE;
    for (let i = 0; i < resultat.Resources.length; i++) {
      this.tableauDonnees.push({
        identifiant: resultat.Resources[i].userName,
        prenom: resultat.Resources[i].name.givenName,
        nom: resultat.Resources[i].name.familyName,
        courriel: resultat.Resources[i].emails[PREMIER_COURRIEL_DEFAUT].value
      });
    }
    this.sourceDeDonnees = new MatTableDataSource<UtilisateurSCIM>(this.tableauDonnees);
    this.nombreComptesTotal = resultat.startIndex + resultat.totalResults;
  }

  ilFautAffinerLaRequete(totalResultats: number, taillePage: number): boolean {
    if (totalResultats === (taillePage + 1)) {
      return true;
    } else {
      return false;
    }
  }

  selectionnerUtilisateur(utilisateur) {
    if (this.selection.isSelected(utilisateur)) {
      this.selection.clear();
    } else {
      this.selection.select(utilisateur);
    }
  }

  allerAuFormulaireAjoutUtilisateur(): Promise<boolean> {
    this.viderMessages();
    this.selection.clear();
    return this.router.navigate(['/nouveau']);
  }

  allerAuFormulaireBoutonModifier(): Promise<boolean> {
    this.viderMessages();
    const ident = this.selection.selected[0].identifiant;
    this.selection.clear();
    return this.router.navigate(['/formulaire', ident]);
  }

  allerAuFormulaireDoubleClique(utilisateur): Promise<boolean> {
    this.viderMessages();
    if (this.selection.isEmpty()) {
      this.selectionnerUtilisateur(utilisateur);
    }
    return this.router.navigate(['/formulaire', this.selection.selected[0].identifiant]);
  }

  private viderMessages() {
    this.message.viderMessage();
    this.traitementErreurs.viderErreurs();
  }

  obtenirNombreComptesParPage(): number {
    return this.pagination.obtenirNombreComptesParPage();
  }

  faireRecherche(identifiantHTML, prenomHTML, nomHTML, courrielHTML) {
    this.message.viderMessage();
    this.pagination.reinitialiserPagination();
    this.paginator.pageIndex = 0;
    this.pagination.changementIdentifiant(identifiantHTML.value);
    this.pagination.changementPrenom(prenomHTML.value);
    this.pagination.changementNom(nomHTML.value);
    this.pagination.changementCourriel(courrielHTML.value);
    this.obtenirUtilisateurs();
  }

  reinitialiser(identifiantHTML, prenomHTML, nomHTML, courrielHTML) {
    identifiantHTML.value = '';
    prenomHTML.value = '';
    nomHTML.value = '';
    courrielHTML.value = '';

    this.pagination.reinitialiserChampsRecherche();

    this.viderTableau();

    this.message.viderMessage();
    this.pagination.reinitialiserPagination();
    this.paginator.pageIndex = 0;
    this.nombreComptesTotal = 0;

    this.traitementErreurs.viderErreurs();

  }

  viderTableau() {
    this.tableauDonnees = [];
    this.sourceDeDonnees = new MatTableDataSource<UtilisateurSCIM>([]);
  }

  auMoinsUnCritereDeRechercheEstSaisi(identifiantHTML, prenomHTML, nomHTML, courrielHTML): boolean {
    return (identifiantHTML.value !== '' ||
            prenomHTML.value !== '' ||
            nomHTML.value !== '' ||
            courrielHTML.value !== '');
  }

  private resultatsObtenusNonVide(result) {
    return result != null && result !== undefined;
  }

  private actualiserPagination(event: PageEvent) {
    this.pagination.changerNombreComptesParPage(event.pageSize);
    this.pagination.changerStartIndex((event.pageIndex * event.pageSize) + 1);

    this.viderMessages();
  }
}



