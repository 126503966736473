import {Injectable, Injector} from '@angular/core';
import { HttpParams, HttpErrorResponse, HttpHeaders  } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { UserScim, Name, EmailsEntity, GroupsEntity, PhoneNumbersEntity, Entreprise, UserExtention } from '../userScim';

const URL_ENDPOINT_SCIM = '/scim/v2/Users/';
const LIEN_LISTE_COMPTES_UTILISATEURS = '.search';
const URL_ENDPOINT_SCIM_GROUPS = '/scim/v2/Groups/';
const LIEN_COURRIEL = '/$send-email';
const RESTE_OTP = '/$reset-otp/';
const RESTE_PASSWORD = '/$reset-password';
const CORPS_VIDE = '';

@Injectable()
export class HttpClientSx5 {

    constructor(private keycloakAngular: KeycloakService, private httpClient: HttpClient, private router: Router) {
    }

    obtenirAccessToken(): Observable<any> {
        let url: string = environment.keycloakRootUrl + environment.keycloakUrlAccessToken;
        url = url.replace('{REALM}', environment.keycloakrealm);

        const requete = 'grant_type=client_credentials&client_id=' +
                        environment.keycloakclientid + '&client_secret=' + environment.keycloakclientsecret;
        return this.httpClient.post(url, requete, { headers: this.obtenirEnTeteKC() }).pipe(
            catchError(err => {
                return this.traitementDErreur(err);
            })
        );
      }

      private obtenirEnTeteKC(): HttpHeaders {
        return new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded'
        });
      }

    rechercheCompteUtilisateurPOSHttp(identifiant: string,
                                      prenom: string,
                                      nom: string,
                                      courriel: string,
                                      startIndex: number,
                                      count: number): Observable<any> {
        const url: string = this.obtenirURLEndpoint() + LIEN_LISTE_COMPTES_UTILISATEURS;
        let paramsString: string;
        const filtre: string = this.miseEnPlaceFiltre(identifiant, prenom, nom, courriel);
        paramsString = '{"schemas": ["urn:ietf:params:scim:api:messages:2.0:SearchRequest"],"startIndex": ' +
                                        startIndex + ',"count":' + count + ',"filter":' + filtre + '}';
        return this.httpClient.post(url, JSON.parse(paramsString), { headers: this.obtenirEnTeteSCIM() }).pipe(
            catchError(err => {
                return this.traitementDErreur(err);
            })
        );
    }

    obtenirUtilisateurParIdentifiantGETHttp(identifiant: string): Observable<any> {
        const url: string = this.obtenirURLEndpoint() + identifiant;
        const parametres = new HttpParams;
        parametres.set('identifiant', identifiant);
        return this.httpClient.get(url, { params: parametres, headers: this.obtenirEnTeteSCIM() }).pipe(
            catchError(err => {
                return this.traitementDErreur(err);
            })
        );
    }

    modifierUtilisateurPUTHttp(identifiant: string, prenom: string, nom: string, courriel: string,
                               actif: boolean, groups: GroupsEntity[], coordonnees: PhoneNumbersEntity[],
                               lieuTravail: string, accountExpires: string): Observable<any> {
        const url: string = this.obtenirURLEndpoint() + identifiant;
        const parametres = new HttpParams;
        parametres.set('identifiant', identifiant);
        const corpsJSON: string = JSON.stringify(this.obtenirCorpsJSONDunUtilisateur(identifiant, prenom, nom, courriel,
                                                                                        actif, groups, coordonnees, lieuTravail,
                                                                                        accountExpires));
        return this.httpClient.put(url, JSON.parse(corpsJSON), { params: parametres, headers: this.obtenirEnTeteSCIM()}).pipe(
            catchError(err => {
                return this.traitementDErreur(err);
            })
        );
    }

    ajouterUtilisateurPOSTHttp(prenom: string, nom: string, courriel: string, groups: GroupsEntity[],
                               coordonnees: PhoneNumbersEntity[], lieuTravail: string): Observable<any> {
        const corpsJSON: string =  JSON.stringify(this.obtenirCorpsJSONNouveauUtilisateur(prenom, nom, courriel,
                                                                                          groups, coordonnees, lieuTravail));
        return this.httpClient.post(this.obtenirURLEndpoint(), JSON.parse(corpsJSON),
                                        {observe: 'response', headers: this.obtenirEnTeteSCIM()}).pipe(
            catchError(err => {
                return this.traitementDErreur(err);
            })
        );
    }

    envoyerCourrielInitialisationPOSTHttp(identifiant: string) {
        const url: string = this.obtenirURLEndpoint() + identifiant + LIEN_COURRIEL;
        return this.httpClient.post(url, CORPS_VIDE).pipe(
            catchError(err => {
                return this.traitementDErreur(err);
            })
        );
    }

    reinitialiserOtpPOSTHttp(identifiant: string, uid: string) {
        const url: string = this.obtenirURLEndpoint() + identifiant + RESTE_OTP + uid;
        return this.httpClient.post(url, CORPS_VIDE).pipe(
            catchError(err => {
                return this.traitementDErreur(err);
            })
        );
    }

    reinitialiserPasswordPOSTHttp(identifiant: string) {
        const isSx5OneStepResetPwd: boolean = this.keycloakAngular.isUserInRole('sx5-one-step-reset-pwd');
        let url: string = this.obtenirURLEndpoint() + identifiant
        if (isSx5OneStepResetPwd) {
            url += RESTE_PASSWORD;    
        } else {
            url += LIEN_COURRIEL;
        }

        return this.httpClient.post(url, CORPS_VIDE).pipe(
            catchError(err => {
                return this.traitementDErreur(err);
            })
        );
    }

    retirerCompteUtilisateurDuDepotDELETEHttp(identifiant: string) {
        const url: string = this.obtenirURLEndpoint() + identifiant;
        return this.httpClient.delete(url, {headers: this.obtenirEnTeteSCIM()}).pipe(
            catchError(err => {
                return this.traitementDErreur(err);
            })
        );
    }

    obtenirListeDeGroupesGETHttp(): Observable<any> {
        const url: string = this.obtenirURLEndpointGroupes();
        return this.httpClient.get(url, { headers: this.obtenirEnTeteSCIM() }).pipe(
            catchError(err => {
                return this.traitementDErreur(err);
            })
        );
    }

    private obtenirEnTeteSCIM(): HttpHeaders {
        return new HttpHeaders({ 'Content-Type': 'application/scim+json'});
    }

    traitementDErreur(error: HttpErrorResponse): Observable<any> {
        if (error.status === 0) {
            return throwError('Le serveur est présentement non disponible, veuillez réessayer plus tard.');
        } else if (error.status === 401) {
            return throwError('Vous êtes déconnecté.');
        } else if (error.status === 403) {
            this.router.navigate(['/interdiction']);
        } else if (error.status === 405) {
            return throwError('L\'utilisateur que vous essayez d\'accéder n\'a pas encore d\'identifiant');
        } else if (error.status === 500) {
            return throwError('Un problème est survenu. Veuillez contacter votre administrateur réseau.');
        }
        return throwError(error.error.detail);
    }

    public obtenirURLEndpoint(): string {
        return environment.sx5ServiceUrl + URL_ENDPOINT_SCIM;
    }

    public obtenirURLEndpointGroupes(): string {
        return environment.sx5ServiceUrl + URL_ENDPOINT_SCIM_GROUPS;
    }

    private obtenirCorpsJSONDunUtilisateur(identifiant: string, prenom: string, nom: string, courriel: string,
                                           actif: boolean, groups: GroupsEntity[],
                                           coordonnees: PhoneNumbersEntity[], lieuTravail: string,
                                           accountExpires: string): UserScim {
        const userScim: UserScim = this.obtenirUserScim(identifiant, prenom, nom, courriel, actif, groups, coordonnees, lieuTravail, accountExpires);
        return userScim;
    }

    private obtenirUserScim(identifiant: string, prenom: string, nom: string, courriel: string, actif?: boolean,
                            groups?: GroupsEntity[], coordonnees?: PhoneNumbersEntity[], lieuTravail?: string,
                            accountExpires?: string) {
        const userScim: UserScim = new UserScim();
        const name: Name = new Name();
        const emails: EmailsEntity[] = [];
        if (identifiant !== undefined && identifiant != null) {
            userScim.userName = this.anticorruption(identifiant);
        }
        name.givenName = this.anticorruption(prenom);
        name.familyName = this.anticorruption(nom);
        userScim.name = name;
        const email: EmailsEntity = new EmailsEntity();
        email.value = this.anticorruption(courriel);
        emails.push(email);
        if (coordonnees.length > 0) {
            userScim.phoneNumbers = coordonnees;
        }
        userScim.emails = emails;
        if (actif !== undefined && actif != null) {
            userScim.active = actif;
        }
        if (groups !== undefined && groups != null) {
            userScim.groups = groups;
        }
        if (lieuTravail !== undefined && lieuTravail !== '') {
            userScim['urn:ietf:params:scim:schemas:extension:enterprise:2.0:User'] = this.ajouterNouveauLieuTravail(lieuTravail);
        }
        if (accountExpires !== undefined) {
            this.ajouterUserExtention(userScim, "accountExpires", accountExpires);
        }
        return userScim;
    }

    private ajouterUserExtention(userScim: UserScim, attrib: string, value: any) {
        if (environment.isAccountExpiresActive) {
            let userExtention: UserExtention = userScim['urn:sx5:scim:api:messages:2.0:UserExtention'];
            if(!userExtention) {
                userExtention = new UserExtention
            }
            userExtention[attrib] = value;
            userScim['urn:sx5:scim:api:messages:2.0:UserExtention'] = userExtention;
        }
    }

    private ajouterNouveauLieuTravail(lieuTravail: string): Entreprise {
        const entreprise: Entreprise = new Entreprise();
        entreprise.organization = this.anticorruption(lieuTravail);
        return entreprise;
      }

    private obtenirCorpsJSONNouveauUtilisateur(prenom: string, nom: string, courriel: string, groups: GroupsEntity[],
                                               coordonnees: PhoneNumbersEntity[], lieuTravail: string): UserScim {
        const userScim: UserScim = this.obtenirUserScim(null, prenom, nom, courriel, null, groups, coordonnees, lieuTravail);
        return userScim;
    }

    private miseEnPlaceFiltre(identifiant: string, prenom: string, nom: string, courriel: string): string {
        let filtre = '';
        if (identifiant !== '') {
            filtre = 'username sw \\"' + this.anticorruption(identifiant) + '\\"';
        }
        if (prenom !== '') {
            filtre.length > 0 ? (filtre = filtre + ' and firstName sw \\"' + this.anticorruption(prenom) + '\\"') : (filtre = 'firstName sw \\"' + this.anticorruption(prenom) + '\\"');
        }
        if (nom !== '') {
            filtre.length > 0 ? (filtre = filtre + ' and lastName sw \\"' +
                                 this.anticorruption(nom) + '\\"') : (filtre = 'lastName sw \\"' + this.anticorruption(nom) + '\\"');
        }
        if (courriel !== '') {
            filtre.length > 0 ? (filtre = filtre + ' and emails[value sw \\"' + this.anticorruption(courriel) + '\\"]') : (filtre = 'emails[value sw \\"' + this.anticorruption(courriel) + '\\"]');
        }
        return '"' + filtre + '"';
    }

    private anticorruption(parametre: string): string {
        if (parametre.includes('"')) {
            const re = /\"/gi;
            const parametreNetoye = parametre.replace(re, '\\"');
            return parametreNetoye;
        }
        return parametre;
    }

}
