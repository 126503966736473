
import {interval as observableInterval,  Observable } from 'rxjs';

import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { KeycloakService } from '../../../node_modules/keycloak-angular';
import { HttpClientSx5 } from './client-http-sx5/http.client.sx5';

@Injectable()
export class SessionCheckService {
    constructor( private keycloakAngular: KeycloakService, private clientHTTPSX5: HttpClientSx5) { }
    
    validate(): Observable<any> {
        return observableInterval(1000).pipe(map( (x) => 
                {
                    var rt:any = this.keycloakAngular.getKeycloakInstance().refreshTokenParsed;
                    var dateExpirationSession:number = rt.exp;
                    var dateActuelle = Math.floor(new Date().getTime() / 1000);
                    if((dateActuelle + 30) === dateExpirationSession){
                        return true;
                    }else{
                        return false;
                    }
                }
        ));
    }
}