<div class="dialogue" (keyup.enter)="fermerDialogue()">
  <h2 mat-dialog-title>Voulez-vous vraiment retirer le compte utilisateur {{donnees.identifiant}}?</h2>
  <div [ngClass]="{ 'messageErreur': this.traitementErreurs.nombreErreursDialogue() != 0 }">
        <li *ngFor="let erreur of this.traitementErreurs.obtenirListeErreursDialogue()">
            {{erreur}}
        </li>
    </div>
  <mat-slide-toggle [(ngModel)]="debloque" class="interrupteur">Je veux retirer le compte utilisateur</mat-slide-toggle>
  <div mat-dialog-actions>
    <button mat-raised-button [disabled]="!debloque" class="boutonRetirer test" (click)="retirerUtilisateur()">Retirer le compte utilisateur</button>
    <button mat-raised-button class="boutonAnnuler" (click)="fermerDialogue()" (keyup.enter)="fermerDialogue()">Annuler</button>
  </div>
</div>
