<header>
  <table width="760" border="0" align="center" cellpadding="0" cellspacing="0" class="tableheader" style="margin:auto; width:760px;">
    <tbody>
    <tr>
      <td width="211">
        <a href="{{urlTheme}}" target="_blank" id="urlTheme">
          <img src="../assets/img/theme/{{theme}}/logo.gif" width="211" height="100" id="logo" border="0">
        </a>
      </td>
      <td align="right" valign="top" id="bannierepiv">
        <div><img src="../assets/img/bandeau.jpg" alt="" width="450" height="57"></div>
        <nav class="menudiv">          
          <a class="langue visible-lecteur-ecran" (click)="deconnexion()" id="deconnexion" title="Déconnexion">Déconnexion</a>
        </nav>
        <p id="usagerConnecte">{{usagerConnecte}}</p>
      </td>
    </tr>
    </tbody>
  </table>
</header>
<router-outlet></router-outlet>
<footer>
  <a href="http://www.gouv.qc.ca/" target="_blank">
    <img src="../assets/img/logoquebecfooter.gif" alt="Portail du gouvernement du Québec" title="Portail du gouvernement du Québec">
  </a>
  <div id="pp-copyright">
    ©
    <a href="http://www.droitauteur.gouv.qc.ca/copyright.php" target="_blank">Gouvernement du Québec, {{today | date:'yyyy'}}</a>
  </div>
  <p>Version: {{version}}</p>
</footer>
<br>
<ng-progress></ng-progress>