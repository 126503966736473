import { Injectable } from '@angular/core';


const AUCUNE_ERREUR_POUR_PAGE_NON_DIALOGUE = 0;

@Injectable({
  providedIn: 'root'
})
export class TraitementErreurService {
  private erreurs: string[] = [];
  private erreursDialogue: string[] = [];
  estDandUnDialogue = false;

  constructor() { }

  traitementErreur(error: string) {
    if (this.estDandUnDialogue) {
      this.erreursDialogue = this.algorithmeTraitementErreur(error);
    } else {
      this.erreurs = this.algorithmeTraitementErreur(error);
    }
  }

  obtenirListeErreurs() {
    return this.erreurs;
  }

  obtenirListeErreursDialogue() {
    return this.erreursDialogue;
  }

  nombreErreurs(): number {
    if (this.estDandUnDialogue) {
      return AUCUNE_ERREUR_POUR_PAGE_NON_DIALOGUE;
    }
    return this.erreurs.length;
  }

  nombreErreursDialogue(): number {
    return this.erreursDialogue.length;
  }

  interrupteurDialogue() {
    if (this.estDandUnDialogue) {
      this.viderErreurs();
      this.estDandUnDialogue = false;
    } else {
      this.estDandUnDialogue = true;
    }
  }

  viderErreurs() {
    this.erreurs = [];
    this.erreursDialogue = [];
  }

  private algorithmeTraitementErreur(error: string): string[] {
    let listeErreurs: string[] = [];
    if (error != null && error.indexOf !== undefined && error.indexOf('|') > -1) {
      listeErreurs = error.split('|');
      return listeErreurs;
    } else {
      listeErreurs.push(error);
      return listeErreurs;
    }
  }

  affecterDesErreurs(error: string): string[] {
      this.erreurs.push(error);
      return this.erreurs;
  }
}
